@import './../../../style.scss';

.card-checkbox {
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #eee;
  position: relative;
  cursor: pointer;

  &>input {
    display: none;
  }

  &>.fa-check {
    opacity: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: all .2s ease;
  }

  &>.bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    z-index: $z-index-card-checkbox-bg;
    transition: all .2s ease;
  }

  &>label {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    cursor: pointer;
    z-index: $z-index-card-checkbox-label;
  }

  &>.title {
    font-size: 14px;
    margin-bottom: 5px;
    position: relative;
  }

  &:hover {
    &>.bg {
      background-color: #f5f5f5;
    }
  }

  &>input:checked {
    &+.bg {
      background-color: #eee;
      &+.fa-check {
        opacity: 1;
      }
    }
  }
}