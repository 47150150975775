.pagination-row {
  display: flex;

  .pagination-buttons {
    flex-wrap: nowrap;
    margin-left: auto;
  }

  &>select.form-control {
    width: auto;
    margin-left: 15px;
  }
}