.logo-brand-wrapper {
    background-color: white;
    padding: 10px;
    border-radius: 100px;
    filter: drop-shadow(-2px 2px 2px rgba(0, 0, 0, 0.1));
}
.logo-brand-text {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 600;
}