@import '../../../assets/scss/common.scss';

.segmented-button {
  display: flex;
  width: 100%;
  border: 1px solid $color-cadet-grey;
  border-radius: 100px;
  overflow: hidden;
  background: white;

  .segment {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 6px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-size: 11px;
    color: $color-charcoal;
    position: relative;
    transition: all 0.2s ease;
    min-height: 20px;

    &:not(:last-child) {
      border-right: 1px solid $color-cadet-grey;
    }

    &:hover:not(.selected) {
      background-color: $color-white-smoke;
    }

    &:focus {
      outline: none;
    }

    &.selected {
      background-color: $color-neon-blue;
      color: white;
    }

    .check-icon {
      margin-right: 8px;
    }

    // Remove border right when the next segment is selected
    &:not(:last-child).selected + .segment,
    &:not(:last-child) + .segment.selected {
      border-left: none;
    }
  }
}
