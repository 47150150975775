@import '../../../assets//scss/common';

.modal-overlay {
    &.show {
      display: block;
      opacity: 1;
    }

    .modal-wrapper {
        padding: 18px;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 2px 4px 18px 0px rgba(0, 0, 0, 0.25);
        .modal-close {
            display: flex;
            justify-content: flex-end; 
            width: 100%; // Takes full width to push content to the end
            &>.btn-close {
                width: 30px;
                height: 30px;
                outline: none;
                border: none;
                background: none;
                svg {
                    width: 14px;
                    height: 14px;
                    fill: $color-cadet-grey;
                }
            }
            
        }
        .modal-content {
            border: none;
            padding: 3px 18px 18px 18px;
            .content-header {
                .content-title {
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 140%; /* 33.6px */
                    margin-bottom: 24px;
                }
                .content-description {
                    color: $color-marengo;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 150%; /* 19.5px */
                    margin-bottom: 24px;
                }
            }
            .content-footer{
                border: none;
                padding-top: 36px;
            }
        }
    }
}

.modal-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 24px;
}