@import '../../../../components/assets/scss/common.scss';

.upgrade-card {
    border: 1px solid $color-dark-orange;
}

.tracking-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .tracking-card-title {
        display: flex;
    }
}
