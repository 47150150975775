@import '../../../../components/assets/scss/common';

google-chart {
  width: 100%;
}

.expand-table {
  display: flex;
  flex-direction: column;
  button span {
    pointer-events: none;
  }
}

.data-tile {
  border: none;
  border-radius: 6px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
  color: $color-charcoal;
  .card-header {
    border: none;
    background: none;
  }
  .tile-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-aluminum;
    margin-bottom: 9px;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    .tile-info {
      &>svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .tile-value {
    /* Adjusts font size dynamically */
    @media (min-width: 768px) {
      font-size: clamp(12px, 2vw, 20px)
      
    }

    @media (min-width: 1200px) {
      font-size: clamp(12px, 1.1vw, 18px);
    }
    
    @media (min-width: 1512px) {
      font-size: 18px;
    }

    white-space: nowrap;
    display: inline-block;
  }
}