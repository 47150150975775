@import './../../../style.scss';

.overlay-screen {
  padding-top: 75px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: $z-index-overlay;
  overflow: auto;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &>.title-bar {
    background-color: #fff;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    font-size: 18px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .25);
    z-index: $z-index-overlay-screen-title-bar;

    &>.title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      margin: 0 30px;
      margin-right: 20px;
    }

    &>.funnel-name {
      font-weight: 300;
    }

    &>.btn-done {
      border-radius: 0;
      background-color: #000;
      box-shadow: none !important;
      color: rgba(255,255,255, .75);
      padding: 15px 50px;
      &:hover {
        color: #fff;
      }
    }
  }
}