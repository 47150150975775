@import "../../assets/scss/common";

.header {
    position: relative;
    height: 60px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 30px 9px 12px;
    color: $color-charcoal;
    border-bottom: 1px solid $color-anti-flash;
    a {
        color: $color-charcoal;
        &:hover {
            text-decoration: none;
        }
    }
    .logo-container {
        position: absolute;
        left: 12px;
        bottom: -9px;
        display: flex;
        align-items: center;
        gap: 12px;
    }
    .topbar-nav {
        display: flex;
        align-items: center;
        gap: 36px;
        font-size: 13px;
        font-weight: 600;
        .topbar-nav-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 9px;
            color: $color-charcoal;
            padding: 9px 0;
            cursor: pointer;
            &:hover {
                color: $color-marengo;
            }
            .nav-icon {
                color: $color-neon-blue;
                svg {
                    width: 20px;
                    height: 20px;
                }
            }
            .nav-text {
                margin: auto 0;
            }
        }
        .item-user {
            padding: 0;
        }
    }
}