.pathing {
    margin-top: 18px;
    margin-bottom: 40px;
    background: #ffffff;
    padding: 20px 15px 30px 15px;
    .chart-header {
        display: flex;
        justify-content: space-between;
        .chart-title {
            padding: 5px 14px;
            font-size: 18px;
            font-weight: 500;
        }
        .btn-rules {
            margin-right: 6px;
            .btn-edit-icon {
                display: inline-block;
                padding-right: 6px;
            }
        }
    }
    
    .chart-loader {
        text-align: center;
        margin: 60px 0;
    }
    .sunburst {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .info-box {
        margin-top: 15px;
    }
}