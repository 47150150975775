@import "../../../../../../components/assets/scss/common";

.base-plate {
    color: $color-marengo;
    font-size: 14px;
    font-weight: 400;
    .image-container {
        display: flex;
        justify-content: center;
        padding-top: 40px;
        img {
            width: 281px;
            height: 256px;
            flex-shrink: 0;
        }
    }
}