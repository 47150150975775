@import './../../../assets/scss/common';
@import './../../../../style.scss';

.insight-ui-slider {
  position: relative;

  &>.track-wrapper {
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    height: 5px;
    overflow: hidden;
    z-index: $z-index-slider-track-wrapper;

    &>.track,
    &>.track-progress {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #f00;
    }

    &>.track {
      z-index: $z-index-slider-track;
      background-color: #ccc;
    }

    &>.track-progress {
      width: 0;
      z-index: $z-index-slider-track-progress;
      background-color: #000;
    }
  }

  &>input[type=range] {
    width: 100%;
    position: relative;
    z-index: $z-index-slider-input;
    -webkit-appearance: none;
    background: transparent;
    outline: none;
    cursor: pointer;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      background-color: $color-primary;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-moz-range-thumb {
      background-color: $color-primary;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
    }

    &::-ms-thumb {
      background-color: $color-primary;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      cursor: pointer;
    }

    &:hover {
      &::-webkit-slider-thumb {
        transform: scale(1);
      }
      &::-moz-range-thumb {
        transform: scale(1);
      }
      &::-ms-thumb {
        transform: scale(1);
      }
    }
  }
}