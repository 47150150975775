.ui-table {
  &>thead {
    &>tr {
      &>th {
        text-align: center;

        &:nth-child(1){
          padding-left: 15px;
          text-align: left;
          width: auto;
        }
        &:nth-child(2) {
          text-align: left;
        }
        &:last-child{
          padding-right: 15px;
          width: 5%;
        }
      }
    }
  }
  &>tbody > tr > td {
    text-align: center;

    &:nth-child(1) {
      padding-left: 15px;
      text-align: left;
    }

    &:nth-child(2) {
      text-align: left;
    }

    &:last-child {
      padding-right: 15px;
    }
  }

  &.funnel-table {
    &>tbody>tr>td {
      &:nth-child(1) {
        width: 30%;
      }
    }
    .gap-2 {
      gap: 8px;
    }
  }
}
.funnel-folder-btn-group {
  margin-bottom: 10px;
  .funnel-folder-btn {
      overflow: hidden;
      white-space: nowrap;
      border: none;
      border-radius: 12px;
      background: transparent;
      margin: 0 8px;
      color: #5a5c69;
      max-width: 300px;


      &:hover {
        color: #000000;
        background-color: #ececf7ee;
      }

      &:active,
      &:focus,
      &.funnel-folder-btn-active {
        box-shadow: none;
        color: #000000;
        font-weight: 700;
      }
    }
}