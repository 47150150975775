.datepicker-dropdown {
    position: relative;
    &>.btn {
        text-decoration: none !important;
    }
    &>.dropdown-menu {
        margin-top: 12px;
        &.show {
            display: flex !important;
        }
        &>.presets-col {
        }
        &>.calendar-col {
            .DayPicker__hidden {
                visibility: visible !important;
            }
            .calendar-footer {
                margin-left: 1.4rem;
                font-size: .7rem;
                    &>p {
                        margin:0;
                    }
                &>p{
                    margin: .2rem 0;
                }
            }
        }
    }
    .calendar-dropdown {
        position: absolute;
        right: calc(100% + 1px);
        left: auto;
        bottom: -1px;
        top: auto;
    }

    .date-presets-col {
        padding: 5px 10px;
        min-width: 160px;
        .compare-tip {
            font-size: .7rem;
        }
        &>.dropdown-item {
            transition: all .2s ease;
            background-color: transparent !important;
            &.selected {
                color: #4e73df;
            }
            &:active {
                color: #000;
            }
        }
    }

    .DayPicker {
        &>.DayPicker-wrapper {
            outline: none !important;
            padding-bottom: .5em;
        }
        
        .DayPicker-NavButton--prev {
            left: 1.5em;
        }

        .DayPicker-NavButton--interactionDisabled {
            display: block;
            opacity: .2;
            cursor: default;
            outline: none;
        }
    }

    .DayPicker-Months {
        outline: none;
        flex-wrap: nowrap;

        &>.DayPicker-Month {
            table-layout: fixed;

            &>.DayPicker-Caption {
                text-align: center;
            }
            &+.DayPicker-Month {
                margin-left: 0;
            }
        }

        .DayPicker-WeekdaysRow {
            &>.DayPicker-Weekday {
                font-size: 12px;
            }
        }

        .DayPicker-Day {
            outline: none;
            border-radius: 0;
            font-size: 11px;
            height: 25px;
            padding: 0;

            &.DayPicker-Day--outside {
                color: rgba(#8B9898, .7)
            }

            &.DayPicker-Day--selected {
                background-color: lighten(#4e73df, 15%);
                &.DayPicker-Day--outside {
                    opacity: 0;
                }
                &.DayPicker-Day--from,
                &.DayPicker-Day--to {
                    background-color: #4e73df;
                }
            }
        }
    }
}

.strip {
    height: 15px;
    width: 3px;
    display: inline-block;
    margin-bottom: -2px;
  }
.strip-blue {
    background-color: #4E73DF;
}
.strip-orange {
    background-color: #EF9E00;
}