.note-modal .modal-body {
  .CodeMirror .CodeMirror-scroll {
    min-height: 200px !important;
    height: 200px;
  }
}

.modal-form-wrapper {
  border-radius: 5px;
  // background-color: #f2f2f2;
  padding: 10px 15px 5px 15px;

  input,
  select,
  textarea {
    // width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;

    &:focus {
      box-shadow: 0 0 0 0.1rem rgb(78, 115, 223, .25);
    }
  }

  .modal-form-col-30 {
    float: left;
    width: 30%;
    margin-top: 6px;
    font-weight: 400;
  }

  .modal-form-col-70 {
    float: left;
    width: 70%;
    margin-top: 6px;
  }

  /* Clear floats after the columns */
  .modal-form-row {
    padding: 8px 0;

    &::after {
      content: "";
      display: table;
      clear: both;

    }
  }

  .note-mode {
    background-color: #1cc88a;
    padding: 5px 10px;
    border-radius: 15px;
    color: #fff;
    margin-left: 5px;
  }


}