.invite-wrapper {
    background: #f8f9fc;
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15);
    border-radius: 8px;
    padding: 20px 18px 18px 18px;
    margin: 0 10px 25px 10px;
    min-width: 98%;
    .inv-header {
        display:flex;
        align-items: center;
        padding-left: 5px;
        .header-icon{
            display: flex;
            position: relative;
            margin-right: 18px;
            margin-top: -2px;
            .header-counter {
                position: absolute;
                top: -6px;
                left: 17px;
                height: 20px;
                width: 20px;
                background-color: #fa6159;
                color: #FFFFFF;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                .counter-value {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
        .header-text {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.04em;
        }
    }
    .inv-line {
        border-bottom: 1px solid #e3e6f0;
        margin: 8px -18px 15px -18px;
    }
    .inv-body {
        .inv-item {
            background: #FFFFFF;
            border-radius: 12px;
            box-shadow: 0 .15rem 1.75rem 0 rgb(227, 230, 240);
            padding: 10px 20px;
            &:not(:last-child) {
                margin-bottom: 14px;
            }
            .item-header {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                .inv-status {
                    display: flex;
                    align-items: center ;
                    margin-right: 10px;
                    color: #5CB85C;
                    .status-icon {
                        margin-right: 6px;
                        margin-top: -2px;
                    }
                    .status-text {
                        
                        text-transform: capitalize;
                    }
                }
                .expired {
                    color: #FD1010;
                }
            }
            .item-line {
                border-bottom: 1px solid #e3e6f0;
                margin: 6px 0 10px 0;
            }
            .expired-line {
                border-top: 1px solid #c6c6c6;
                margin: 6px 0 12px 0;
            }
            .item-body {
                display: flex;
                justify-content: space-between;
                .body-left {
                    display: flex;
                    .inv-icon {
                        margin: 9px 35px 0 12px;
                    }
                    .body-main {
                        letter-spacing: 0.02em;
                        .main-title {
                            font-size: 14px;
                            font-weight: 900;
                        }
                        .main-paragrah {
                            font-size: 12.8px;
                            margin-top: 5px;
                        }
                    }
                }
                .body-right {
                    display: flex;
                    margin-top: 8px;
                    .accept {
                        border: none;
                        margin-right: 20px;
                    }
                    .reject {
                        border: none;
                    }
                }
            }
        }
        .expired {
            background: #eaeaea;
        }
    }
}
