@import '../../../assets/scss/common';
@import '../../../../style.scss';

.notification {
    display: flex;
    align-items: center;
    padding: 6px 24px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 1px 3px #0000001a,0 1px 2px #0000000f;
    color: $color-charcoal; 
    position: fixed; /* Ensure the notification stays fixed in the viewport */
    top: 3px; /* Position at the top */
    left: 50%; 
    transform: translateX(-50%); 
    z-index: $z-index-notification; /* Ensure the notification is on top of everything */
    max-width: 600px;
    margin: 0 auto;

    .notification-icon {
        font-size: 24px;
        color: $color-caribbean-green;
        margin-right: 12px;
        margin-top: -2px;
    }

    .notification-content {
        flex: 1;
        .notification-message {
            font-size: 14px;
            color: $color-marengo; 
        }
    }

    .notification-close {
        background: none;
        border: none;
        font-size: 20px;
        color: $color-cadet-grey;
        cursor: pointer;
        padding: 0;
        margin: -2px 0 0 18px;

        &:hover {
            color: $color-charcoal;
        }
    }
}
