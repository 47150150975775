@import './../../../../style.scss';

.custom-control.custom-switch {
  &.label-first {
    text-align: right;
    padding-left: 0;
    padding-right: 2.25rem;
    &>.custom-control-label {
      &:before {
        left: auto;
        right: -2.25rem;
      }
      &:after {
        left: auto;
        right: calc(-1.5rem + 2px);
      }
    }
  }
}

.custom-control.custom-switch.compare-switch {
  input + label {
    cursor: pointer;
  }
  input:checked + label {
    &:before {
      border-color: #EF9E00;
      background-color: #EF9E00;
      box-shadow: none;
    }
    &:after {
      border-color: #fff;
      background-color: #fff;
      box-shadow: none;
    }
  }
  input:focus:not(:checked) + label{
    &:before {
      border-color: #b7b9cc;
      box-shadow: none;
    }
    &:after {
      border-color: none;
      box-shadow: none;
    }
  }
}

.switch-funnel{
  position: relative;
  display: inline-block;
  margin-right: 50px;
  .funnel-slider {
      position: absolute;
      cursor: pointer;
      width: 42px;
      height: 26px;
      background-color: #bebebe;
      border-radius: 15px;
      &::after {
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          margin: 3px;
          background-color: #ffffff;
          border-radius: 50%;
          box-shadow: 1px 3px 3px 1px rgba(0,0,0,0.2);
          transition: 0.2s;
          -webkit-transition: 0.2s;
      }
  }
  .funnel-check-box {
      position: absolute;
      width: 42px;
      height: 26px;
      opacity: 0;
      z-index: $z-index-switch;
      border-radius: 15px;
      &:checked {
          +.funnel-slider {
              background-color: #4e73df;
              &::after {
                  content: '';
                  display: block;
                  width: 18px;
                  height: 18px;
                  margin-left: 21px;
                  border-radius: 50%;
                  transition: 0.2;
                  -webkit-transition: 0.2s;
              }
          }
      }
  }
}