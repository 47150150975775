@import './../../../../style.scss';

.ui-form {
  position: relative;

  &>.form-overlay {
    background-color: rgba(255,255,255, .5);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $z-index-overlay;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}