@import "../../assets/scss/common";

.funnel-viewer {
  padding-bottom: 30px;

  .funnel-report {
    .funnel-widget {
      margin-top: 30px;
      margin-bottom: 6px;
    }
    
    .funnel-graph-container {
      background: white;
      padding: 30px 0;
      border: none;
      border-radius: 6px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    }

    .step-col + .step-col {
      margin-top: 40px;
    }
    .funnel-step > .counter-col {
      top: 35px;
    }

    .step-col {
      .funnel-step > .line {
        display: block;
        top: 75px;
        bottom: -66px;
      }

      &:nth-child(2) {
        .funnel-step > .data-col {
          padding-top: 10px;
        }
      }

      &:last-child {
        .funnel-step > .line {
          display: none;
        }
        .funnel-step > .data-col > .dropoff-session > .dropoff-value {
          display: none;
        }
      }

      &:not(:nth-child(2)) {
        .funnel-step > .data-col {
          padding-top: 10px;
          border-top: 1px solid $color-anti-flash;
        }
      }

      &:nth-last-child(2){
        .funnel-step > .line {
          bottom: -67px;
        }

        .data-col > .bar-col > .bar >.session-bar:after {
          bottom: -78px;
        }
      }
    }

    .graph-heading {
      margin: 0;
      color: $color-aluminum;
      font-weight: bold;
      &>.col {
        flex-grow: 0;
        flex-shrink: 0;
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 10px;
        text-align: center;

        &:nth-child(1){
          padding: 0;
          text-align: center;
          flex-basis: 5%;
        }
        &:nth-child(2){
          width: 15%;
          flex-basis: 15%;
          padding: 0 6px 0 15px;
        }
        &:nth-child(3){
          width: 60%;
          flex-basis: 60%;
          margin-left: 20px;
        }
        &:nth-child(4){
          width: 10%;
          flex-basis: 10%;
          margin-left: 15px;
        }
      }
    }

    .data-col {
      width: 100%;
      display: flex;
      padding-bottom: 10px;
      border-bottom: 1px solid $color-anti-flash;

      &>.bar-col {
        flex-grow: 1;

        &>.step-name {
          font-size: 10px;
          margin-bottom: 5px;

          &>strong {
            text-transform: uppercase;
          }
        }

        &>.bar {
          position: relative;
          height: 45px;
          background-color: $color-cloud-white;

          &>.dropoff-bar,
          &>.session-bar {
            transition: width .3s ease, background-color .3s ease;
            position: absolute;
            left: 0;
            width: 0;
            top: 0;
            bottom: 0;
            cursor: pointer;
          }

          &>.session-bar {
            background-color: $color-marengo;
            &:after {
              content: " ";
              display: block;
              position: absolute;
              top: 45px;
              right: 0;
              bottom: -78px;
              border-right: 1px dashed #ddd;
            }

            &:hover {
              background-color: #3a3b45 !important;
            }
          }

          &>.dropoff-bar {
            background-image: repeating-linear-gradient(
              45deg,
              #bbb,
              #bbb,
              3px,
              transparent 3px,
              transparent 20px
            );
            background-color: #eedada;
            transition: background-color .3s ease;
            &:hover {
              background-color: #ffaaaa;
            }
          }
        }
      }

      &>.session-data {
        width: 15%;
        flex-basis: 15%;
        text-align: center;
        padding: 0 6px 0 15px;
        margin-top: 30px;
        color: $color-charcoal;

        &>strong {
          display: block;
          line-height: 1;
          font-size: 24px;
        }

        &>small {
          font-size: 12px;
        }
      }

      &>.completed-rate {
        width: 10%;
        padding-right: 15px;
        position: relative;
        color: $color-caribbean-green;
        &>.value {
          position: absolute;
          top: 130%;
          font-size: 18px;
        }
        &>.triangle {
          position: absolute;
          top: 130%;
          left: -80%;
          svg {
            transform: rotate(180deg);
          }
        }
      }

      &>.dropoff-rate {
        display: flex;
        flex-direction: column;
        width: 12%;
        height: 45px;
        align-items: center;
        padding-left: 30px;
        text-align: center;
        position: relative;

        &>.label {
          font-size: 11px;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          text-align: center;
          text-transform: uppercase;
          white-space: nowrap;
        }

        &>.trend {
          color: #e74a3b;
          font-size: 18px;
          white-space: nowrap;

          &.negative {
            position: absolute;
            top: 187%;
            .triangle {
              margin-left: 12px;
              svg {
                margin-top: -2px;
                display: inline-block;
                transform: rotate(90deg);
              }
            }
          }
          &.positive {
            position: absolute;
            top: 187%;
            color: #858796;
          }
        }
      }

      &>.dropoff-session {
        font-size: 24px;
        align-self: center;
        padding-top: 20px;
        margin: 0 15px 10px 15px;
        width: 10%;
        text-align: center;
        position: relative;
        color: $color-aluminum;
        .dropoff-value {
          position: absolute;
          width: 100%;
          top: 306%;
          &.up {
            position: absolute;
            width: 100%;
            top: 307%;
          }
        }
      }

      &>.actions {
        margin-top: 20px;
        display: flex;
        align-self: center;
        color: $color-charcoal;

        .dropdown-item {
          cursor: pointer;
        }

        &>.btn {
          margin: 0 10px;
        }
      }
    }

    .step-col:last-child {
      .bar .session-bar:after {
        display: none;
      }
    }
  }

}