$color-primary: #4e73df;
$color-success: #1cc88a;
$color-info: #36b9cc;
$color-warning: #f6c23e;
$color-danger: #e74a3b;
$color-secondary: #858796;

$color-gray-100: #f8f9fc;
$color-gray-200: #eaecf4;
$color-gray-300: #dddfeb;
$color-gray-400: #d1d3e2;
$color-gray-500: #b7b9cc;
$color-gray-600: #858796;
$color-gray-700: #6e707e;
$color-gray-800: #5a5c69;
$color-gray-900: #3a3b45;

// new design system
$color-neon-blue: #4a4afa;
$color-neon-blue-hover: #5C5CFA;
$color-caribbean-green: #05CF9B;
$color-caribbean-green-hover: #20D4A5;
$color-coral-red: #F94E4E;
$color-coral-red-hover: #FA6060;
$color-dark-orange: #FD9041;
$color-dark-orange-hover: #FD9B54;
$color-ochre-yellow: #F6CC60;
$color-ochre-yellow-hover: #F7D16F;
$color-lavender: #8A57DD;
$color-lavender-hover: #9668E0;
$insightech-gradient: linear-gradient(90deg, #4a4afa 0%, #05cf9b 100%);
$Winter-gradient: linear-gradient(180deg, #E8EDF0 0%, #FFF 100%);

$color-charcoal: #222425;
$color-marengo: #4B4F5B;
$color-aluminum: #7b8489;
$color-cadet-grey: #9ca6ab;
$color-ghost: #c3cdd2;
$color-alice-blue: #dee5ec;
$color-anti-flash: #e8edf0;
$color-white-smoke: #f0f3f5;
$color-cloud-white: #f5f7f8;
$color-baby-powder: #fafbfa;
