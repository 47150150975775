.funnel-label {
    margin-right: 10px;
    color: black;
}

.folder-label {
    display: inline-block;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 7px;
    color: black;
}

.folders-dropdown {
    width: 300px;
    padding: 10px
}

.folders-dropdown-footer {
    border-top: 1px solid #eee;
    padding-top: 15px;
}

.folders-description {
    color: #37352FA6;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 2px;
}

.folders-create {
        display: flex;
        height: 31px;
        margin-bottom: 10px;
        position: relative;


        &>.form-control {
            box-shadow: none;
            
        }
    }

.folders-table {
    margin-bottom: 0;
    td {
        vertical-align: middle;
        padding: .5rem .3rem;

        input[type=radio] {
            position: relative;
            bottom: -2px;
        }
    }

}

.funnels-number {
    display: inline-block;
    height: 20px;
    min-width: 20px;
    line-height: 20px;
    border-radius: 20px;
    padding: 0px 10px;
    margin-left: 4px;
    font-size: 75%;
    font-weight: 600;
    text-align: center;
    color: rgb(43, 34, 51);
    background: rgb(240, 236, 243);
    position: relative;
}


.create-folder-text {
    margin-left: 10px;
    padding: 2px;
    background-color: #DDDFEB;
}


