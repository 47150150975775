@import './z-indexes.scss';

$tooltip-sizes: (
  sm: 220px,
  md: 300px,
  lg: 440px,
  xl: 600px
);

@mixin tooltip-size($size) {
  text-transform: none;
  z-index: $z-index-tooltip;
  max-width: map-get($tooltip-sizes, $size);
}

.tooltip-sm {
  @include tooltip-size(sm);
}

.tooltip-md {
  @include tooltip-size(md);
}

.tooltip-lg {
  @include tooltip-size(lg);
}

.tooltip-xl {
  @include tooltip-size(xl);
}

.tooltip-with-url {
  word-break: break-all;
}
