.styled-checkbox {
    position: absolute;
    display: none;

    &.checkbox-compared {
      &:checked + label:before {
        background: #EF9E00;
        border: none;
      }
    }
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      bottom: -6px;
    }
  
    & + label:before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      border: 1px solid black;
    }
  
    &:checked + label:before {
      background: #0174FF;
      border: none;
    }
    
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }
  
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }
  
    &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 7px;
    background: white;
    width: 1.5px;
    height: 1.5px;
    box-shadow:
        1.5px 0 0 white,
        3px 0 0 white,
        3px -1.5px 0 white,
        3px -3px 0 white,
        3px -4.5px 0 white,
        3px -6px 0 white;
    transform: rotate(45deg);
    }
  }