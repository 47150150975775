.comment-block {
  margin-top: 10px;
  display: flex;
  .form-control {
    font-size: 12px;
  }
  .comment-col1 {
    &>img {
      width: 32px;
    }
  }
  .comment-col2 {
    flex-grow: 1;
    margin-left: 10px;
    &>.comment-body {
      &>.comment-author {
        display: block;
        font-weight: 700;
        color: #333;
      }
      border-radius: 5px;
      padding: 5px 10px;
      font-size: 12px;
    }
  }

  .comment-actions a {
    position: relative;
  }
}