.collapsable {
  .title {
    margin: 0;
    .btn {
      font-size: 14px;
      font-weight: 700;
      width: 100%;
      text-align: left;

      .fa {
        font-size: 10px;
        margin-right: 10px;
        position: relative;
        top: -1px;
        transition: all .2s ease;
      }

      &.collapsed {
        .fa {
          transform: rotate(-90deg);
        }
      }
    }
  }

  &>.body {
    padding: 15px;
  }
}