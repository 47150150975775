@import './../../../assets/scss/common';

.insight-ui-tag {
  border-radius: 5px;
  display: inline-block;
  padding: 2px 8px;
  font-size: 11px;
  cursor: pointer;
  background-color: $color-gray-100;
  border: 2px solid $color-gray-300;
  transition: all .2s ease;

  &>.d-flex {
    align-items: center;
  }

  .btn-delete {
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    outline: none;
  }

  &:hover {
    background-color: $color-gray-200;
  }
}

.tag-analytics {
  background-color: #1cc88a;
  color: #ffffff;
  border: none;
}