@import '../../../../assets/scss/common';

.report-form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    

    &.scroll-height {
        max-height: 62vh;
        overflow-y: auto;
    }

    input, select, textarea {
        display: flex;
        height: 50px;
        padding: 9px 12px;
        align-items: center;
        border-radius: 6px;
        border: 1px solid $color-anti-flash;
        background: white;
        &:focus {
            box-shadow: 0 0 0 0.1rem rgb(78,115,223,.25);
        }
    }
    
    .row-label {
        display: flex;
        gap: 10px;
        color: $color-charcoal;
        font-size: 14px;
        font-weight: 500;
        line-height: 150%; /* 21px */
        label {
            margin: 0;
        }
    }

    .row-tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        .plain-text {
            font-size: 13px;
            color: $color-charcoal;
        }
    }
    
    /* Clear floats after the columns */
    .modal-form-row{
        display: flex;
        flex-direction: column;
        gap: 18px;
    }
    
}