.ui-table {
  tbody > tr {
    cursor: pointer;

    &>td {
      vertical-align: middle;
    }

    &:last-child > td {
      border-bottom: 1px solid #e3e6f0;
    }
  }
}

.users-table.ui-table {
  thead > tr > th {
    width: auto;
  }
}