@import './../../../../components/assets/scss/common';

.tracking-code-card {
  .card-body {
    .method-toggle {
      border: 1px solid #ccc;
      padding-top: 1rem;
      min-width: 180px;
      &.active {
        border-color: $color-primary;
        background-color: $color-primary;
        color: #fff;
      }
      .fa {
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
        font-size: 2.5rem;
      }
      img {
        display: block;
        margin: 0 auto;
        margin-bottom: 5px;
        width: 40px;
        height: 40px;
        object-fit: contain;
      }
      &:hover:not(.active) img.white-on-active {
        filter: brightness(0.7);
      }
      &.active img.white-on-active {
        filter: brightness(0) invert(1);
      }
    }

    h3 {
      margin-top: 2rem;
    }
  }
}
