@import './../../assets/scss/common';

.insight-progress-bar {
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &>.insight-progress {
    background-color: $color-primary;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
}