@import '../../../assets/scss/common';
$md-radio-size: 20px;
$md-radio-checked-size: 8px; 

.radio-button {
    margin-right: 24px;

    input[type="radio"] {
        display: none;
        &:checked + label:before {
            border-color: $color-anti-flash;
        }
        &:checked + label:after {
            transform: scale(1);
        }
    }

    label {
        display: inline-block;
        position: relative;
        padding: 0 calc($md-radio-size + 10px);
        margin-bottom: 0;
        cursor: pointer;
        vertical-align: bottom;
        color: $color-charcoal;
        font-size: 13px;
        font-weight: 400;
        &:before, &:after {
            position: absolute;            
            content: '';  
            border-radius: 50%;
            transition: all .3s ease;
            transition-property: transform, border-color;
        }
        &:before {
            left: 0;
            top: 0;
            width: $md-radio-size;
            height: $md-radio-size;
            border: 2px solid $color-anti-flash;
        }
        &:after {
            top: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
            left: calc($md-radio-size / 2 - $md-radio-checked-size / 2);
            width:$md-radio-checked-size;
            height:$md-radio-checked-size;
            transform: scale(0);
            background: $color-neon-blue;
        }
    }
}
