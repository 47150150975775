@import "../../../../../components/assets/scss/common";

.list-table {
    margin-bottom: 12px;
    &>thead {
        font-weight: 400;
        font-size: 11px;
        color: $color-aluminum;
        &>tr {
            &>th {
                border: none;
                padding: 0 0 20px 0;
                &:last-child {
                    visibility: hidden;
                }
            }
        }
    }
    &>tbody {
        &>tr {
            border-top: 1px solid $color-white-smoke;
            &>td {
                padding: 18px 40px 21px 0;
                font-size: 13px;
                font-weight: 400;
                color: $color-charcoal;
                &>.report-type {
                    display: flex;
                    align-items: flex-start;
                    gap: 9px;
                }
                &>.action-group {
                    display: flex;
                    gap: 12px;
                }
                .btn-icon {
                    width: 24px;
                    height: 24px;
                    padding: 3.43px;
                    border: none;
                    border-radius: 3px;
                    background: $color-white-smoke;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &:hover {
                        background: $color-baby-powder;
                    }
                    &:focus {
                        outline: none;
                    }
                    a {
                        color: inherit;
                    }
                }
            }
        }
        &>:first-child {
            border-top: 3px solid $color-white-smoke;
        }
        &>:last-child {
            border-bottom: 1px solid $color-white-smoke;
        }
    }
}