@import '../../../../assets/scss/common';

.floating-label {
    width: 100%;
    position: relative;

    input {
        box-sizing: border-box;
        width: 100%;
        padding: 8.5px 12px 8.5px 12px;
        padding-top: 24.5px;
        transition: border-color 0.3s;
        color: $color-charcoal;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;

        border-radius: 6px;
        border: 1px solid $color-anti-flash;
        background: white;
        &:focus {
            outline: none;
            box-shadow: none;
        }
    }

    label {
        position: absolute;
        top: 15px;
        left: 12px;
        transition: all 0.3s;
        pointer-events: none;
        color: $color-charcoal;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
        .optional-text {
            font-style: italic;
            font-weight: 400;
            line-height: normal;
            color: $color-cadet-grey;
            margin-left: 5px; // slight space between label and optional text
        }
    }

    &.filled label,
    input:focus + label {
        top: 8.5px;
        left: 12px;
        color: $color-cadet-grey;
        font-size: 11px;
        font-weight: 400;
        line-height: normal;
        .optional-text {
            font-weight: 300;
        }
    }

    &.error input {
        border-color: $color-coral-red; // Red color for errors
        color: $color-coral-red;
    }

    &.error .error-message {
        color: $color-coral-red;
        font-size: 11px;
        font-weight: 400;
        position: absolute;
        top: 100%;
        left: 1%;
        margin-top: 3px;
        line-height: 150%; /* 16.5px */
    }
}
