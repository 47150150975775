@import "../../assets/scss/common";

.header-left {
  display: flex;
  align-items: center;

  .page-description {
    margin-left: 12px;
    cursor: pointer;
    
    svg {
      width: 20px;
      height: 20px;
      color: $color-aluminum;
    }
  }
}

.header-right {
  display: flex;
  align-items: center;
  gap: 12px;
  .list-btn-group {
    .star-list-btn {
      overflow: hidden;
      white-space:nowrap;
      border: none;
      border-radius: 12px;
      background: transparent;
      margin: 0 3px;
      color: #5a5c69;
      max-width: 150px;
      text-overflow: ellipsis; 
      &:hover {
        color: #000000;
        background-color: #ececf7ee;
      }
      &:active, &:focus, &.star-list-btn-active {
        box-shadow: none;
        color: #000000;
        font-weight: 700;
      }
    }
  }
}