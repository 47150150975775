.plan-upgrade-wrapper {
  display: flex;
  justify-content: center;
}

.w-layout-blockcontainer {
  max-width: 1400px;
  margin: 0;
  padding: 40px 0px;
}

.container-10 {
  position: relative;
  text-align: center;
}

.hero-h1 {
  color: #16213e;
  font-size: 30px;
  margin-bottom: 24px;
  font-weight: 700;
  line-height: 1.5em;
}

.w-layout-grid {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  padding: 20px;
}

.grid-20 {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
}

.div-block-32 {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
}

.hero-descr {
  color: #16213e;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
}

.trial-form-grey {
  background-color: #f4f4f4;
  border-radius: 20px;
  padding: 20px;
}

.button-5.outline-cta {
  display: inline-block;
  padding: 12px 24px;
  background-color: transparent;
  color: #007bff;
  border: 2px solid #007bff;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  text-align: center;
  transition: all 0.3s ease;
  
  &:hover {
    background-color: #007bff;
    color: white;
  }
}

img {
  max-width: 100%;
  height: auto;
}

@media screen and (max-width: 991px) {
  .w-layout-grid {
    grid-template-columns: 1fr;
  }
  
  .grid-20 {
    padding: 20px;
  }
}

#hubspot-form-container {
  min-height: 400px;
}
