@import './../../assets/scss/common';

.replay-note {
  .replay-note-meta {
    .note-sharable {
      margin-left: 8px;
    }
    .creation-time {
      font-size: 10px;
    }
  }
  &.compact {
    .owner-btns {
      display: flex;
    }
    .comment-block {
      .comment-col2 {
        position: relative;
        .inline-confirmation {
          left: 0;
          right: 0;
          p {
            white-space: normal;
          }
        }
      }
      .comment-actions > a {
        position: static;
      }
    }
  }
}

.note-description, .note-date-range {
  margin-bottom: 10px;
  .note-row-label {
    display: flex;
    margin-bottom: 6px;
    .note-label-icon {
      margin: -2px 4px 0 0;
    }
  }
  .note-row-content {
    color:#37352F;
    white-space: pre-wrap;

    .note-content-link {
      color: #0066cc;
      text-decoration: none;
      word-break: break-all;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.comment-form {
  textarea {
    border-color: $color-gray-400;
    width: 100%;
    font-size: 12px;
    padding: 5px 10px;

    &:active,
    &:focus {
      border-color: $color-gray-400;
    }
  }
}
