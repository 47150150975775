@import '../../assets/scss/common';

.reports-dropdown-wrapper {
  display: inline-block;
  >.dropdown-menu {
    padding: 30px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 2px 4px 18px 0px rgba(0, 0, 0, 0.25);
    width: 860px;
    margin-top: 5px;
    .reports-dropdown-menu {
      display: flex;
      flex-direction: column;
      gap: 30px;
      color: $color-aluminum;
      .reports-table-wrapper {
        max-height: 40vh;
        padding: 0 3px;
        overflow: auto;
        .reports-table {
            margin-bottom: 0;
            &>thead {
              color: $color-aluminum;
              font-size: 11px;
              font-weight: 400;
              line-height: 150%; /* 16.5px */
              &>tr {
                &>th {
                  border-top: none;
                  border-bottom: 3px solid $color-white-smoke;
                  padding: 0 0 20px 0;
                }
              }
            }
            &>tbody {
              &>tr {
                &>td {
                  vertical-align: top;
                  padding: 20px 40px 20px 0;
                  border-top: none;
                  border-bottom: 1px solid $color-white-smoke;
                }
                &>:last-child {
                  padding: 20px 20px 20px 0;
                }
              }
            }
          }
      }

      .reports-footer {
        display: flex;
        justify-content: center;
      }
    }
  }
}