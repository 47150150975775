@import './../../../../style.scss';
@import '../../../../components/assets/scss/common.scss';

#replay-div {
    .card-area {
        position: relative;
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: $z-index-overlay;
            background-color: rgba(255,255,255, .5);
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 70px;

            .loader-with-text {
                background-color: #fff;
                padding: 30px;
                border-radius: 10px;

            }
        }
    }

}

.visited-replay {
    color: $color-marengo;
    .play-icon {
        svg {
            color: rgba($color-neon-blue, 0.7) !important;
        }
    }
}

.session-con-rev {
    padding: 2px 4px;
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
}

.z-index-0 {
    z-index: $z-index-replay-pagination !important;
}