@import '../../assets/scss/common';

.dropdown.profile-selector-dropdown {
  &>.dropdown-toggle {
    height: 4.375rem;
  }
  .dropdown-item {
    white-space: normal;
    padding-left: 30px;
  }
}