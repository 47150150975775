.segments-dropdown {
  width: 800px;
  padding: 15px;
  margin-top: 12px;

  .badge-pill {
    display: inline-block;
    line-height: 1.2;
  }
  .badge-pill + .badge-pill {
    margin-left: 5px;
  }

  .dropdown-footer {
    border-top: 1px solid #eee;
    padding-top: 15px;
    p {
      font-size: 0.75rem;
      span {
        padding-left: 0.125rem;
      }
    }
  }

  .segment-search {
    display: flex;
    height: 31px;
    margin-bottom: 10px;
    position: relative;
    &>.fa {
      line-height: 31px;
      position: absolute;
      left: 10px;
      top: 0;
    }

    &>.form-control {
      box-shadow: none;
      padding-left: 35px;
    }
  }
}

.segments-table {
  margin-bottom: 0;
  td {
    vertical-align: middle;
    padding: .5rem .75rem;

    input[type=checkbox]{
      position: relative;
      bottom: -2px;
    }
  }
}

.segments-table-wrapper {
  max-height: 370px;
  overflow: auto;
}

@media screen and (max-height: 700px) {
  .segments-table-wrapper {
      max-height: 35vh;
  }
}
@media screen and (max-height: 400px) {
  .segments-table-wrapper {
      max-height: 20vh;
  }
}

.segment-dropdown-wrapper {
  display: inline-block;
}