.firebase-signin {
  .firebaseui-container {
    max-width: none;

    .firebaseui-card-content {
      padding: 0;
    }
  }

  .firebaseui-idp-list {
    margin: 10px 0;

    &>.firebaseui-list-item {
      margin-bottom: 10px;

      .firebaseui-idp-button {
        max-width: none;
        text-align: center;

        &>span {
          display: inline;
        }

        .firebaseui-idp-text-short {
          display: none;
        }
      }
    }
  }
}