@import "../../assets/scss/common";

.btn-chips {
    display: flex;
    align-items: center;
    background: $color-caribbean-green;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 13px;
    padding: 0;
    .text {
        padding: 9px 9px 9px 12px;
        border-radius: 6px;
        white-space: nowrap;
        &:hover {
            background: #05BA8B;
        }
    }
    .icon {
        padding: 9px 12px 9px 9px;
        border-radius: 6px;
        svg {
            margin-top: -2px;
        }
        &:hover {
            background: #05BA8B;
        }
    }
    &:focus {
        outline: none;
    }
    &.white-smoke {
        background: $color-white-smoke;
        color: $color-charcoal;
        border: 1px solid $color-anti-flash;
        .text {
            &:hover {
                background: $color-baby-powder;
            }
        }
        .icon {
            color: $color-cadet-grey;
            &:hover {
                background: $color-baby-powder;
            }
        }
    }
    &.dark-orange {
        background: $color-dark-orange;
        .text {
            &:hover {
                background: $color-dark-orange-hover;
            }
        }
        .icon {
            &:hover {
                background: $color-dark-orange-hover;
            }
        }
    }
}