.segment-selector {
    margin-left: 0;
    margin-right: 0;
    flex-flow: row;
    align-items: flex-start !important;

    &+hr {
        margin-top: 10px;
    }

    &>.segments-col {
        display: flex;
        flex-wrap: wrap;

        &>.btn {
            margin-bottom: 10px;
        }
    }

    .list-toggle {
        margin-bottom: 10px;
        display: inline-block;
    }
}

.quickfilter-card {
    .card-body {
        padding: .75rem 1.25rem;
    }
}

.filters-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .filters-group-row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .group-row-left {
            display: flex;
            flex-wrap: wrap;
            gap: 12px;
            align-items: center;
            .filter-dropdown {
                position: absolute;
                left: 0;
                width: 100%;
                margin-top: 12px;
                .filter-dropdown-container {
                    width: -webkit-fill-available;
                    margin: 0 1.5rem 0 1.5rem;
                }
            }
        }
    }
}


