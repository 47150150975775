
@import '../../assets/scss/common';

.search-section {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 9px 12px;
    border-radius: 6px;
    border: 1px solid $color-anti-flash;
    background: white;
    &>.form-control {
        box-shadow: none;
        border: none;
        padding: 0;
        color: $color-charcoal;
        font-size: 13px;
        font-weight: 500;
        line-height: normal;
    }
}