@import "../../../../../../components/assets/scss/common";

.data-title {
    display: flex;
    gap: 12px;
    .title-link {
        font-size: 14px;
        font-weight: 700;
        padding: 0;
        color: $color-neon-blue;
        border: none;
        background: none;
        text-align: left;
        &:focus {
            outline: none;
        }
        &:hover {
            color: darken($color-neon-blue, 10%);
        }
    }
    a:hover {
        text-decoration: none;
    }
    &>.report-sharable {
        &>svg {
            color: $color-ghost;
            width: 18px;
            height: 18px;
            margin-top: -3px;
        }
    }
}
.data-descr {
    padding-top: 6px;
    font-size: 11px;
    color: $color-aluminum;
    max-width: 234px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}