@import "../../assets/scss/common";
@import "../../../style.scss";

.sidebar {
    font-family: Rubik, sans-serif;
    display: flex;
    flex-direction: column;
    flex-shrink: 0; // prevent shrinking
    gap: 6px;
    max-width: 202px;
    margin-left: 18px;
    padding: 1.5rem 0;
    height: 100%;
    position: sticky;
    top: 0;
    z-index: 10;
    
    &::-webkit-scrollbar {
        width: 5px;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
        border: 1px solid $color-white-smoke;
    }
    
    &::-webkit-scrollbar-thumb {
        background: $color-alice-blue;
        border-radius: 5px;
    }

    #sidebar-menu-tooltip {
        display: none;
    }
    .sidebar-menu {
        cursor: pointer;

        &:hover {
            border-radius: 100px;
            background: $color-white-smoke;
            color: $color-marengo;
            a { text-decoration: none; }
        } 

        &.active {
            border-radius: 33px;
            background: $insightech-gradient;
            box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
            .sidebar-menu-item {
                color: white;
                font-weight: 600;
                padding: 9px;
            }
        }
    }

    .sidebar-menu-item {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 18px;
        font-size: 14px;
        color: #282c2d;
        font-weight: 500;
        padding: 9px;
        margin-left: 0px;
        width: 100%;
        .sidebar-menu-title {
            flex-grow: 1;
            flex-basis: auto;
            margin: auto 0;
        }
    }

    // Submenu styles
    .submenu-container {
        border-radius: 20px;
        &:hover {
            border-radius: 100px;
            background: $color-white-smoke;
            color: $color-marengo;
            a { text-decoration: none; }
        } 

        &.expanded {
            background: $color-alice-blue;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
            &:hover {
                border-radius: 20px;
            }
        }

        .submenu {
            display: flex;
            flex-direction: column;
            gap: 3px;
            padding: 9px;
            padding-top: 5px;
            background: white;
            border-radius: 0 0 20px 20px;
        }

        .submenu-item {
            display: flex;
            align-items: center;
            padding: 9px;
            color: #282c2d;
            text-decoration: none;
            font-size: 12px;
            border-radius: 20px;
            margin: 2px 0;

            &:hover {
                background: $color-white-smoke;
            }

            &.active {
                background: $insightech-gradient;
                color: white;
                font-weight: 600;
                box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.10);
            }

            .submenu-title {
                margin-left: 8px;
            }

            .submenu-icon {
                width: 24px;
                height: 24px;
                min-width: 24px;
                svg {
                    width: 16px;
                    height: 16px; 
                }
            }
        }
    }

    .sidebar-footer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 16px;
        padding-left: 8px;
        width: 100%;
        .footer-icon {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            background-color: $color-alice-blue;
            border-radius: 50%;
            min-width: 32px;
            flex-shrink: 0;  /* prevent shrinking */
            svg {
                width: 20px;
                height: 20px; 
                color: white;
            }
        }
    }
}

.sidebar-menu.show {
    .sidebar-menu-item {
        border-radius: 100px;
        background: $color-anti-flash;
        color: $color-marengo;
    }
}

.collapsed {
    max-width: 50px;
    z-index: 11;
    #sidebar-menu-tooltip {
        display: block;
    }
    .sidebar-menu-item {
        padding: 9px;
        .sidebar-menu-title {
            display: none;
        }
    }
    .submenu {
        align-items: center;
        .submenu-item {
            .submenu-title {
                display: none;
            }
        }
    }
}

.sidebar.sticky.collapsed {
    z-index: $z-index-sidebar-tooltip;
}

.nav-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background-color: white;
    border-radius: 50%;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    min-width: 32px;
    flex-shrink: 0;  /* prevent shrinking */
    svg {
        width: 20px;
        height: 20px;
        color: $color-neon-blue;
    }
}