@import '../../../assets/scss/common';

.ui-dropdown-container {
  position: relative;
  display: inline-block;
  span {
    font-weight: 600;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  // Dropdown menu
  .ui-dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    width: 286px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    padding: 3px 0 8px 0;
    max-height: 70vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 5px;
    } 
  
    &::-webkit-scrollbar-track {
        background: white;
        border: 1px solid $color-white-smoke;
    }
  
    &::-webkit-scrollbar-thumb {
        background: $color-alice-blue;
        border-radius: 5px;
    }

    &>.dropdown-header {
      font-weight: 800;
      color: #b7b9cc;
      font-size: .65rem;
      margin-top: 5px;
    }
    &>.dropdown-item {
      padding-left: 30px;
      white-space: normal;
    }
  }
}
