$z-index-notification: 2000;
$z-index-attribute-dropdown: 1011; // filter panel attribute(user/event) dropdown menu
$z-index-toast: 1010;
$z-index-drawer: 1001;
$z-index-modal: 1000;
$z-index-overlay: 100;
$z-index-funnel-screen: 100;
$z-index-sidebar-tooltip: 11; // collapsed sidebar tooltip, need to be higher than sticky header
$z-index-sticky-header: 10;
$z-index-tooltip: 10;
$z-index-inline-confirmation: 5;
$z-index-slider-input: 5;
$z-index-overlay-screen-title-bar: 5;
$z-index-replay-events-nav: 2;
$z-index-replay-control: 2;
$z-index-card-checkbox-label: 2;
$z-index-slider-track-progress: 2;
$z-index-face-angry-icon: 1;
$z-index-table-header: 1;
$z-index-switch: 1;
$z-index-slider-track-wrapper: 1;
$z-index-replay-filter-header: 1;
$z-index-slider-track: 1;
$z-index-replay-pagination: 0;
$z-index-card-checkbox-bg: -1;