.ui-table {
  tbody > tr {
    cursor: pointer;

    &>td {
      vertical-align: middle;
    }

    &:last-child > td {
      border-bottom: 1px solid #e3e6f0;
    }
  }
}

.segment-list-table {
  &> thead,
  &> tbody {
    &>tr {
      &>th:first-child,
      &>td:first-child {
        width: 50%;
      }
    }
  }
}