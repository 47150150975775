@import './../../assets/scss/common';
@import './../../../style.scss';

.replay-events-nav {
  padding: 30px;
  position: absolute;
  bottom: 91px;
  left: 0;
  right: 0;
  z-index: $z-index-replay-events-nav;
  background-color: lighten(#000, 25%);

  .heading {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: .8rem;
    font-weight: 700;
    color: #aaa;
  }

  .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    color: #fff !important;
  }

  &>.nav-meta {
    text-align: center;
    line-height: 1rem;
    margin-bottom: 30px;

    .link {
      font-size: .8rem;
      font-weight: 400;
      text-decoration: underline;
      color: #fff;
    }

    .title {
      font-size: 1rem;

      &>h3 {
        display: inline-block;
        font-size: 1rem;
        font-weight: 300;
        color: #fff;
        margin: 0 15px;
      }

      .timestamp {
        font-size: .75rem;
        padding: 2px 15px;
        position: relative;
        top: -2px;
        transition: all .3s ease;
      }

      strong {
        font-size: 1rem;
        color: #fff;

        &>.fa {
          margin-left: 5px;
          font-size: .8rem;
          color: $color-danger;
        }
      }
    }
  }

  &>.nav-content {
    position: relative;
    padding: 0 15px;

    &>.nav-btn {
      font-size: 1.5rem;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: #fff;
      &:hover {
        color:#fff;
      }

      &.btn-next {
        right: -15px;
      }
      &.btn-prev {
        left: -15px;
      }
    }

    &>.slide-wrapper {
      overflow: hidden;
      margin: 0 30px;

      &>.slides {
        white-space: nowrap;
        position: relative;
        transition: transform .3s ease;

        &>.slide-page {
          display: inline-block;
          width: 100%;

          &>.slide-page-content {
            display: flex;
            flex-direction: row-reverse;

            &>div {
              flex-grow: 1;
              position: relative;
              text-align: center;

              &>.btn {
                padding: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                border-radius: 50%;
                border: 1px solid #fff;
                color: #fff;

                &:hover {
                  background-color: #fff;
                  color: lighten(#000, 25%);
                }
              }

              &:after,
              &:before {
                content: " ";
                display: block;
                position: absolute;
                top: 50%;
                border-top: 1px dashed #fff;
              }

              &:before {
                left: 2px;
                right: calc(50% + 20px);
              }

              &:after {
                right: -2px;
                margin-right: 2px;
                left: calc(50% + 20px);
              }

              &.elapsed {
                &>.btn {
                  background-color: #fff;
                  color: lighten(#000, 25%);
                }

                &:before {
                  border-top: 2px solid #fff;
                }

                &:first-child,
                &+.elapsed {
                  &:after {
                    margin-right: 0;
                    border-top: 2px solid #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}