@import './components/assets/scss/common';

.btn-ghost {
  border: 1px dashed #ccc;
  &>.icon {
    background-color: transparent;
    color: #ccc;
  }
}

.btn {
  &.btn-plain,
  &.btn-default {
    &:hover {
      color: #000;
    }
  }

  &.btn-primary {
    color: #fff;
  }

  &.btn-pill {
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.dropdown-item {
  outline: none !important;
  cursor: pointer;
}

.custom-control {
  &.custom-switch {
    &>.custom-control-label {
      line-height: 1.5rem;
    }
  }
}

input.form-control {
  &::placeholder {
    opacity: .3;
  }
}

.form-control-error {
  border-color: $color-danger;
  &:focus {
    border-color: $color-danger;
    box-shadow: 0 0 0 .2rem rgba($color-danger, .25) !important;
  }
}

.dropdown-toggle:after {
  margin-left: 8px;
}

/* Table */
.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.text-neon-blue {
  color: $color-neon-blue;
}

.text-charcoal {
  color: $color-charcoal;
}

.text-alluminum {
  color: $color-aluminum;
}