@import '../../../components/assets/scss/common';

.stacked-bar-chart-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .stacked-bar-chart {
    display: flex;
    width: 100%;
    min-width: 222px;
    height: 24px;
    border-radius: 6px;
    overflow: hidden;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    
    .bar-segment {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5a5c69;
      font-weight: 400;
      transition: all 0.2s ease;
      gap: 5px;
      font-size: clamp(10px, 1vw, 12.8px);

      .segment-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      
      &:hover {
        filter: brightness(1.05);
      }
    }
  }
}
