@import "./../../../style.scss";
@import "../../assets/scss/common";

.funnel-screen {
  &.funnel-builder{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: $z-index-funnel-screen;
    overflow: auto;
  }
  &>.title-bar {
    display: flex;
    align-items: center;
    font-size: 18px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .25);

    &>.title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 700;
      margin: 0 30px;
      margin-right: 20px;
    }

    &>.funnel-name {
      font-weight: 300;
    }

    .funnel-printer-loading {
      margin-right: 36px;
    }

    .funnel-printer {
      margin-right: 20px;
    }

    &>.btn-done {
      border-radius: 0;
      background-color: #000;
      box-shadow: none !important;
      color: #fff !important;
      padding: 15px 50px;
      &:hover {
        color: #fff;
      }
    }
  }

  &>.funnel-report {
    &>.funnel-form {
      padding: 15px 45px;
      margin-top: 7px;
      border-bottom: 2px solid #eee;
    }
  
    &>.funnel-details {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      margin-top: 7px;
      border-bottom: 2px solid #eee;
  
      &>.funnel-meta {
        &>.funnel-description {
          margin-bottom: 0;
        }
        &>.funnel-tags {
          margin-top: -9px;
        }
      }
  
      &>h1 {
        align-self: flex-start;
        margin-right: 60px;
      }
  
      &>.funnel-actions {
        display: flex;
      }
    }
  
    .funnel-steps-container {
      border: 2px dashed #eee;
      margin: 60px;
      padding: 60px 0;
  
      &.submitted {
        .step-col.invalid {
          .counter {
            background-color: #f00;
          }
        }
      }
  
      &.invalid {
        .step-col:last-child {
          .line {
            display: none;
          }
        }
      }
  
      &.max-steps {
        &>.row > .step-col:last-child .line {
          display: none !important;
        }
      }
    }
  
    .step-col+.step-col {
      margin-top: 30px;
    }
  
    .step-col {
      &>.btn-add {
        position: absolute;
        left: 0;
        padding: 0 8px;
        box-shadow: none !important;
  
        .fa {
          margin-right: 10px;
        }
      }
  
      .line {
        display: none;
  
        &.show {
          display: block;
        }
      }
  
      &.valid {
        .line {
          display: block;
        }
      }
    }
  
    .add-btn-col {
      margin-top: 30px;
      &>.btn-add {
        margin-left: 20px;
      }
    }
  
    .funnel-step {
      display: flex;
      position: relative;
      align-items: flex-start;
  
      &>.funnel-step-form {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        &>.rule-wrapper {
          padding: 15px 0;
  
          &>label {
            &>.fa {
              margin-right: 10px;
            }
          }
        }
      }
  
      &>.counter-col {
        flex-shrink: 0;
        position: relative;
        top: 4px;
        width: 45px;
        text-align: center;
  
        &>.counter {
          display: inline-block;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          font-size: 12px;
          text-align: center;
          line-height: 32px;
          color: #fff;
          background-color: $color-ghost;
        }
      }
  
      &>.line {
        position: absolute;
        left: 22px;
        top: 40px;
        bottom: -30px;
        border-right: 1px dashed $color-anti-flash;
      }
    }
  }
}