@import '../../../style.scss';

.dashboard-table-wrapper {
    overflow: auto;
    max-height: 494px;
    .dashboard-table {
        min-width: 500px;
        white-space: nowrap;
        th {
            padding: .5rem .5rem;
            font-size: .8rem;
            font-weight: 400;
            border-top: hidden;
            text-align: center;
            &:nth-child(1){
                padding-left: 32px;
                text-align: left;
                width: auto;
            }
        }
        td {
            font-size: .8rem;
            text-align: center;
            &:nth-child(1) {
                padding-left: 36px;
                text-align: left;
            }
            .cell-ellipsis {
                display: inline-block; /* This makes the span respect the width and overflow settings */
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100px; // Width on smaller screens
                @media screen and (min-width: 1680px) {
                    width: 200px;
                }
                @media screen and (min-width: 1980px) {
                    width: 275px;
                }
                @media screen and (min-width: 2100px) {
                    width: 400px;
                }
                &.lg {
                    width: 320px; // Width on smaller screens
                    @media screen and (min-width: 1680px) {
                        width: 500px;
                    }
                    @media screen and (min-width: 1980px) {
                        width: 650px;
                    }
                }
                &.xlg {
                    width: 400px; // Width on smaller screens
                    @media screen and (min-width: 1680px) {
                        width: 1000px;
                    }
                    @media screen and (min-width: 1980px) {
                        width: 1200px;
                    }
                }
            }
            .comparison-cell-ellipsis {
                display: inline-block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 500px; // Width on smaller screens
                @media screen and (min-width: 1680px) {
                    width: 600px;
                }
                @media screen and (min-width: 1980px) {
                    width: 700px;
                }
            }
        }
    }
    .btn-view-all {
        display: flex;
        justify-content: center;
    }
}

.fixed-table-header {
    top: 0;
    position: sticky;
    z-index: $z-index-table-header;
    background: white;
}

.zebra-dark {
    background-color: #f8f8f8;
}

.table-row {
    position: relative;
    .filter-icon-container {
        position: absolute;
        top: 50%;
        left: 6px;
        transform: translateY(-50%);
        display: none;
        border: none;
        border-radius: 50%;
        background-color: #dfdfdf;
        padding: 3px;
        cursor: pointer;
        transition: opacity 0.3s;
    }
    &:hover {
        .filter-icon-container {
            display: inline-block;
            .filter-icon {
                margin: 4px;
                opacity: 1;
                color: #858796;
            }
        }
    }
}