.public-layout {
  min-height: 100vh;
}

.public-main {

  &>.page-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .main-logo {
    position: static;
    left: 35px;
    top: 20px;

    @media screen and (max-width: 991px) {
      position: static;
      margin: 40px 0 20px;
    }
  }
}