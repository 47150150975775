.ui-table {
  width: auto;
  &>thead {
    &>tr {
      &>th {
        white-space: nowrap;
        padding: .5rem 1rem;
        font-size: .8rem;
        font-weight: 400;
        border-bottom: none !important;

        &>.pageHeader {
          cursor: pointer;
          &>.fa {
            position: relative;
            left: 5px;
            &.fa-sort-up {
              top: 3px;
            }
            &.fa-sort-down {
              top: -1px;
            }
          }
        }
      }
    }
  }
  &>tbody {
    &>tr>td {
      font-size: .8rem;
    }
  }
  &.loading,
  &.no-data {
    &>tbody>tr>td {
      text-align: center !important;
    }
  }
}

.hscroll {
  overflow-x: auto;
}