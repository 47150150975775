@import '../../../assets/scss/common';

.card-header {
    .clipboard-wrapper {
        display: flex;
        align-items: center;
        color: $color-aluminum;
        position: relative;

        .copy-status {
            font-size: 12px;
            margin-right: 8px;
            opacity: 0;
            visibility: hidden; // Initially hidden
            transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out, color 0.3s ease-in-out;

            &.copied {
                color: $color-success;
            }
        }

        &:hover .copy-status {
            opacity: 1;
            visibility: visible; // Make text visible on hover
        }

        .btn-table-clipboard {
            background: none;
            border: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            &:active, &:focus {
                outline: none;
            }

            svg {
                color: $color-aluminum;
            }
        }
    }
}
