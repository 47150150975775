@import '../../../assets/scss/common.scss';

.toggle-button {
    position: relative;
    display: flex;
    padding: 2px;
    border: 1px solid $color-marengo;
    border-radius: 15px;
    font-size: 14px;
    background-color: #fff;
    cursor: pointer;

    .button-option {
        position: relative;
        padding: 2px 9px;
        text-align: center;
        z-index: 1;
        color: $color-marengo;
        text-wrap: nowrap;

        &.active {
            color: #fff;
            background-color: $color-marengo;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);       
            border-radius: 15px;
            transition: transform 0.3s ease-in;
        }
    }
}