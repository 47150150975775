.note-grid {
  display: flex;
  flex-wrap: wrap;
  &>.note-cell {
    padding: 7px;
    flex-shrink: 0;
    flex-basis: 50%;

    &>.replay-note {
      margin: 0;
    }

    .note-url {
      max-width: 400px;
    }
  }
}