@import './../../assets/scss/common';
@import './../../../style.scss';

.replay-viewer {
  display: flex;
  flex: 1;
  height: 100%;
  .main-panel {
    flex-grow: 1;

    &>#replay-div {
      height: 100%;

      &>.col {
        height: 100%;

        &>.card {
          height: 100%;
        }
      }
    }
  }
  .side-panel {
    min-width: 300px;
    flex-grow: 0;
    padding: 20px;
    flex-basis: 350px;
    flex-shrink: 0;
    height: 100%;
    display: flex;
    flex-direction: column;

    &>.nav-pills {
      display: flex;
      border-bottom: 1px solid #eee;

      &>.nav-item {
        flex-grow: 1;
        text-align: center;
        &+.nav-item  {
          margin-left: 10px;
        }
      }

      .btn {
        border-radius: 0;
        border-left: none;
        border-right: none;
        border-bottom: 3px solid transparent;
        width: 100%;
      }

      .btn.active {
        font-weight: bold;
        border-bottom-color: #aaa;
      }
    }

    &>.tab-container {
      padding: 15px 0;
      overflow-y: auto;
      overflow-x: hidden;
      flex-grow: 1;
      height: 1px;

      &>.card-checkbox {
        margin-bottom: 2px;
        word-break: break-word;
      }
    }
  }
}

.filter-header {
  position: fixed;
  top: 129px;
  min-width: 295px;
  background-color: #FFFFFF;
  z-index: $z-index-replay-filter-header;
  padding: 5px 0 5px 0;
}

.event-filter-modal {
  .row > .col > p {
    margin-bottom: 10px;
    &>label {
      font-size: 90%;
      position: relative;
      margin-left: 3px;
      top: -1px;
    }
  }
}

table.event-list {
  position: relative;
  top: 31px;
  tr {
    cursor: pointer;
  }
  td {
    font-size: 12px;
    position: relative;
    vertical-align: middle;
    padding: .5rem .75rem;
    &>.buttons {
      display: none;
    }
  }
  .timestamp {
    display: block;
  }
  tr:hover,
  tr.active {
    &>td:first-child {
      &:before {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        border-left: 2px solid $color-primary;
      }
    }
    &>td {
      color: #000;
      font-weight: 700;
      &>.buttons {
        display: inline-block;
      }
      &>.timestamp {
        font-weight: normal;
        color: #fff;
        background-color: #000;
        transition: all .3s ease;
      }
    }
  }
}

.event-details-drawer {
  .json-container {
    word-break: break-all;
  }
}

.replay-note {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: inset 0 0 0 2px #eee;
  border-radius: 8px;

  &.active {
    box-shadow: inset 0 0 0 2px $color-primary;
  }

  &>.replay-note-tags {
    margin-bottom: 8px;
    &>.insight-ui-tag {
      &+.insight-ui-tag {
        margin-left: 5px;
      }
    }
  }
  &>.replay-note-meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    &>img {
      width: 26px;
      height: 26px;
      overflow: hidden;
      margin-left: 5px;
    }
    .fa-bolt {
      color: $color-danger;
    }

    .meta-row {
      font-style: italic;
      font-size: 90%;
      flex-basis: 100%;
      flex-shrink: 0;
      flex-grow: 1;
      color: $color-gray-500;
      word-break: break-all;
    }
  }
  &>.replay-note-body {
    margin: 15px 0 14px 5px;
  }
}

#replay-control {
  box-shadow: 0 0px 10px 0 rgba(0,0,0, .25);
  border-right: 2px solid $color-gray-200;
  padding: 15px;
  margin-top: 10px;
  position: relative;
  z-index: $z-index-replay-control;

  .progress-markers {
    // width: 100%;
    height: 20px;
    position: relative;
    margin-top: -5px;
    margin-left: -12px;
    margin-right: -5px;
    &>.fa {
      font-size: 18px;
      position: absolute;
      top: 0;
    }
    &>.fa-face-angry {
      z-index: $z-index-face-angry-icon;
    }
  }

  .playlist-navigator {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 0;
    flex-direction: column;
    position: relative;
    padding-right: 20px;

    &>.label {
      font-size: 10px;
      margin-bottom: 5px;
      flex-grow: 1;
      text-align: center;
      text-transform: uppercase;
    }

    &>.controls {
      display: flex;

      &>.playlist-progress {
        white-space: nowrap;
        padding: 10px 20px;
        background-color: #eee;
        border-radius: 20px;
      }
    }

    &:after {
      content: " ";
      background-color: #ccc;
      display: block;
      position: absolute;
      right: -3px;
      bottom: 0;
      width: 3px;
      top: 20px;
    }
  }

  .divider {
    
    width: 3px;
    margin: 0 30px;
    margin-top: 20px;
  }

  &>.insight-ui-slider {
    position: absolute;
    top: -8px;
    left: 0;
    right: 0;
  }

  .control-group {
    display: flex;
    margin-top: 20px;
    align-items: center;
    flex-grow: 0;
  }

  .play-progress-parent {
    margin-left: auto;
  }

  .play-controls {
    display: flex;
    position: relative;
    padding-right: 10px;

    &>.play-progress {
      font-size: 14px;
    }

    &:after {
      content: " ";
      display: block;
      border-right: 1px solid #ccc;
      position: absolute;
      right: 0;
      top: 10px;
      bottom: 10px;
    }
  }
}

#replay-div {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &>.replay-url {
    display: flex;
    justify-content: space-between;
    .replay-url-link {
      margin: 15px;
      margin-top: 0;
      max-width: 600px;
      position: relative;
      &> a {
        display: block;
        margin: 4px 0 5px 0;
        height: 15px;
        font-size: 12.8px;
        font-weight: 700;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .url-clipboard {
      position: relative;
      border: none;
      background: #FFFFFF;
      margin-right: 2px;
      margin-top: -13px;
      &::after {
        content: "Copy URL";
        display: none;
        position: absolute;
        width: 140px;
        height: 36px;
        top: 32%;
        right: 0%;
        font-size: .8rem;
        font-weight: 600;
        color: #858796;
      }
      &:hover {
        &::after {
          display: block;
        }
      }
      &:active, &:focus {
        outline: none;
        &:after {
          content: 'Copied!';
          right: 10%;
          color: #188d4f;
        }
      }
    }
  }

  #replay-screen-area {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &>#replay-background {
      background-color: $color-gray-200;
      display: flex;
      flex: 1;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: $z-index-overlay;
      background-color: rgba(255, 255, 255, .5);
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 70px;

      .loader-with-text {
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;

      }
    }
  }
}

#replay-scale {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);

  #replay-iframe {
    transform-origin: 0 0 0;
  }
}

#replay-screen-area.fullscreen {
  #replay-control {
    padding: 0 15px 15px 15px;

    .btn:hover {
      color: #fff;
    }

    .progress-markers{
      margin-top: 10px;
    }
  }
}

.clickmap-stats,
.element-stats {
  &>h4 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    padding: 0 .5rem;
    &>.copy-icon {
      cursor: pointer;
      margin-left: 9px;
      margin-top: -2px;
      width: 16px;
      height: 16px;
      color: $color-aluminum;
    }
  }

  &>.element-tag {
    padding: 0 8px;
    margin-bottom: 18px;
  }

  &>.element-path {
    padding: 0 .5rem;
    color: $color-marengo;
    word-break: break-all;
    font-size: 12px;
  }

  .table {
    margin-bottom: 18px;
    &> tbody > tr > td {
      border: none;
      border-bottom: 1px solid $color-gray-300;
      padding: .7rem .3rem;
      vertical-align: middle;
      &.stat-name {
        white-space: nowrap;
        width: 100%;
        &>.icon >svg {
          margin-right: 5px;
          margin-top: -2px;
          width: 16px;
          height: 16px;
        }
      }
      &.stat-value {
        font-weight: 400;
        font-size: 14px;
        color: #000;
      }
      &.stat-percentage {
        font-weight: 100;
        font-size: 14px;
        color: #000;
      }
    }
  }
}

.clickmap-stats {
  .table {
    &> tbody > tr > td {
      &.stat-name {
        white-space: nowrap;
        width: auto;
      }
      &.stat-value {
        font-size: 14px;
        text-align: right;
        width: 100%;
        white-space: nowrap;
      }
    }
  }
}

.export-icon {
  margin-top: -1.5px;
}