@import './../../../../components/assets/scss/common';

.search-url {
  display: flex;
  gap: 9px;
  margin-bottom: 1rem;
}

.search-area {
  position: relative;
  
  .search-button {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding-right: 60px;
  }
}

.device-option {
  margin-bottom: 1rem;
  &>input {
    position: relative;
    bottom: -1px;
  }

  &+.device-option {
    margin-left: 1rem;
  }
}

.ui-table {
  .btn-primary {
    white-space: nowrap;
  }

  .fa-sort-up,
  .fa-sort-down {
    color: $color-warning;
  }

  .fa-sort {
    opacity: .25;
  }

  &>thead > tr > th:nth-child(2),
  &>tbody > tr > td:nth-child(2){
    width: 500px;
  }
}

.copy-icon {
  margin: 6px 3px;
  border: none;
  background: none;
  svg {
    width: 18px;
    height: 18px;
    color: $color-aluminum;
    &:hover {
      color: $color-marengo;
    }
    &:focus {
      outline: none;
    }
  }
}

.popup-container {
  position: relative;
  display: flex;
  align-items: center;
}

tr {
  .url-copy-button {
    visibility: hidden;
    flex-shrink: 0;
    background: white;
    border-radius: 15px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  &:hover {
    .url-copy-button {
      visibility: visible;
    }
  }
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 18px;
  .analytics-action {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  }
  .solid {
    background: $color-neon-blue;
    border: none;
    &:hover {
      background: $color-neon-blue-hover;
    }
  }
  .outlined {
    border: 1px solid $color-neon-blue;
    &:hover {
      border: 1px solid $color-neon-blue-hover;
    }
  }
  .upgrade {
    border: 1px solid $color-dark-orange;
    &:hover {
      border: 1px solid $color-dark-orange-hover;
    }
  }
}