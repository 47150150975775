@import "../../../assets/scss/common";

.btn {
    &:focus {
        outline: none;
        box-shadow: none;
    }
    &.btn-menu {
        color: $color-neon-blue;
        padding: 4px 12px 4px 8px;
        border-radius: 4px;
        &:hover {
            color: $color-marengo;
        }
        &:focus {
            box-shadow: none;
        }
    }
    .btn-text {
        white-space: nowrap;
    }
    &>.btn-icon + .btn-text {
        margin-left: 5px;
    }

    &.btn-xs {
        border-radius: 3px;
        font-size: .65rem;
        padding: .15rem .5rem;
    }
}

// global button style
.btn-compared {
    color: #fff;
    background-color: #EF9E00;
    border-color: #EF9E00;
    &:hover {
        color: #fff;
        background-color: #E29500;
        border-color: #E29500;
    }
}