.segments-drawer {
  .ui-form {
    position: static;
    padding: 0 15px;

    .filters-section {
      padding: 15px 45px;
      margin-left: -45px;
      margin-right: -45px;
    }

    .drawer-buttons {
      display: flex;
      padding: 15px 45px;
      margin-left: -45px;
      margin-right: -45px;
      margin-bottom: -30px;
      box-shadow: 0 0 10px 0 rgba(0,0,0, .5);
    }

    .boolean-field {
      display: flex;
      &>.label {
        color: #000;
        &>.fa {
          color: #858796;
          margin-right: 10px;
        }
      }
      &>.separator {
        flex-grow: 1;
        height: 12px;
        margin: 0 10px;
        border-bottom: 1px solid #ccc;
      }
      &>.custom-control {
        top: -2px;
      }
    }
  }
}