.modal {
  background-color: rgba(255,255,255, .5);
  &.show {
    display: block;
    opacity: 1;
  }
  .modal-dialog {
    .modal-content {
      .modal-header {
        font-size: 1rem;
        padding: 30px;
        padding-bottom: 15px;
        border: none;
        &>.close {
          outline: none;
          padding: 0;
          line-height: 0;
          text-align: 0;
          display: block;
          width: 30px;
          height: 30px;
          position: absolute;
          top: 40px;
          right: 40px;
        }
        .modal-title {
          display: flex;
          .title-icon {
            margin-left: 12px;
            margin-top: -1px;
          }
        }
      }
      .modal-body {
        padding: 15px 30px;
      }
      .modal-footer {
        border: none;
        padding: 30px;
        padding-top: 15px;
      }
      .modal-additional-info {
        border-top: 1px solid #eee;
        margin: 0 30px;
        padding-top: 24px;
        padding-bottom: 36px;
      }
    }
  }
}