.segment-builder {
    padding: 0.75rem 1.25rem;

    hr {
        margin: 0 0 .5rem 0;
    }

    .filters-toggle {
        margin: .5rem 0 .25rem 0.1rem;
        &>small {
            text-transform: uppercase;
            font-size: 70%;
        }
        &>.btn {
            color: #000;
            margin: 0 10px;
            position: relative;
            top: -2px;
            font-size: 95%;

            .badge {
                border-radius: 10px;
                padding: 0 5px;
                top: -2px;
                height: 15px;
                line-height: 14px;
                min-width: 15px;
            }
        }
    }

    .filters-panel {
        overflow: auto;
        transition: all .5s ease;
        max-height: 460px;
        margin-bottom: 10px;
        padding: 15px;
    }

    @media screen and (max-height: 700px) {
        .filters-panel {
            max-height: 40vh;
        }
    }
    @media screen and (max-height: 550px) {
        .filters-panel {
            max-height: 30vh;
        }
    }

    &>.actions {
        &>.btn {
            min-width: 150px;
        }
    }
}

.shadow-pulse {
    animation: pulse 2s infinite;
    box-shadow: 0 0 0 rgba(231, 74, 59, 0.4);

    @keyframes pulse {
        0% {
            -moz-box-shadow: 0 0 0 0 rgba(231, 74, 59, 0.4);
            box-shadow: 0 0 0 0 rgba(231, 74, 59, 0.4);
        }
        70% {
            -moz-box-shadow: 0 0 0 25px rgba(204,169,44, 0);
            box-shadow: 0 0 0 25px rgba(204,169,44, 0);
        }
        100% {
            -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
            box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        }
    }
}