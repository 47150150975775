@import '../../../style.scss';

.ui-toast {
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  max-width: 600px;
  margin: 0 auto;
  padding: 5px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: $z-index-toast;
  &.success {
    &>.fa {
      color: #1cc88a;
    }
  }
  &.danger {
    &>.fa {
      color: #e74a3b;
    }
  }
  &>.btn {
    box-shadow: none !important;
    margin-right: -10px;
  }
  &>p {
    margin: 0;
    margin-left: 10px;
    margin-right: auto;
  }
}