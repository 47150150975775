.dropdown-menu {
  &>.btn {
    border-radius: 0;
    &:active {
      background-color: inherit;
    }
  }
}

.dropdown {
  &.share-menu {
    .dropdown-menu {
      padding: 1rem .5rem;
      margin-top: 0;
    }
  }
}