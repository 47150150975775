.alert-wrapper {
    margin: 0 12px;
    .alert-box {
        margin: 10px 0;
        padding: 10px 15px 10px 12px;
        background: #fff2cd;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .alert-text {
            font-size: 17px;
            display: flex;
            align-items: center;
            .text-icon {
                margin-top: -2px;
                padding-right: 5px;
            }
        }
    }
}