@import '../../../../style.scss';
@import '../../../assets/scss/common';

.modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $z-index-modal;
    background: #f8f9fc;
    padding: 12px 20px;
    box-shadow: 1px 1px 1px #474747, -1px -1px 1px #474747;
    color: #000000;
    border-radius: 10px;
    .modal-line {
        margin: 10px -20px;
    }
    .modal-main {
        span {
            font-size: 20px;
            letter-spacing: 0.02em;
        }
        margin-top: 10px;
    }
    .modal-btns {
        display: flex;
        justify-content: flex-end;
        margin: 20px 0 8px 0;
        font-size: 17px;
        letter-spacing: 0.03em;
        .modal-close {
            margin-right: 25px;
            .close-btn {
                border: 1px solid #dfdfdf;
                border-radius: 4px;
                background: none;
                padding: 4px 18px;
                &:hover {
                    background: #e6e6e6;
                }
            }
        }
        .modal-confirm {
            .delete-btn {
                border: none;
                border-radius: 4px;
                background: $color-coral-red;
                color: #FFFFFF;
                padding: 4px 18px;
                width: 167px;
                &:hover{
                    background: #E04647;
                }
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color: #000000, $alpha: 0.6);
    z-index: $z-index-modal;
    backdrop-filter: blur(6px);
}
