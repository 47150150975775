@import './../../../../components/assets/scss/common';

.device-option {
  margin-bottom: 1rem;
  &>input {
    position: relative;
    bottom: -1px;
  }

  &+.device-option {
    margin-left: 1rem;
  }
}

.ui-table {
  .btn-primary {
    white-space: nowrap;
  }

  .fa-sort-up,
  .fa-sort-down {
    color: $color-warning;
  }

  &>thead>tr>th:nth-child(2),
  &>tbody>tr>td:nth-child(2) {
    width: 500px;
  }
  &>thead>tr>th:nth-child(3),
  &>tbody>tr>td:nth-child(3) {
    width: 500px;
  }

  &>tbody>tr>td .url-span {
    display: block;
  }
}

.text-charcoal {
  color: $color-charcoal;
}

.text-alluminum {
  color: $color-aluminum;
}
