@import './../../../style.scss';

.inline-confirmation {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $z-index-inline-confirmation;
  cursor: default;

  &.show {
    display: block;
  }

  &>.card-body {
    &>p {
      padding: 10px 15px 0 15px;
      text-align: center;
      white-space: nowrap;
    }
    padding: 10px;
  }

  &>.card-header {
    padding: 10px;
    align-items: center;
    .card-title {
      font-weight: 700;
      margin: 2px 0 0 0;
      text-align: center;
    }
  }

  .btn {
    width: 100%;
  }

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    padding: 0;
    text-align: center;
  }

}