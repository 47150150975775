@import '../../../../style.scss';

.drawer-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0, .5);
    z-index: $z-index-drawer;

    &>.drawer {
        width: 75%;
        background-color: #fff;
        position: absolute;
        box-shadow: 0 0 30px 0 rgba(0,0,0, .25);
        padding: 30px;

        right: 0;
        bottom: 0;
        top: 0;
        overflow: auto;

        &>.close {
            position: absolute;
            top: 20px;
            right: 30px;
        }

        &>.drawer-title {
            font-size: 14px;
            padding-bottom: 10px;
            margin-bottom: 30px;
            border-bottom: 1px solid #ccc;
            text-transform: uppercase;
        }
    }
}