@import './../../assets/scss/common';

.clickmap-card {
  padding: 10px;
  cursor: pointer;
  
  .clickmap-card-title {
    font-size: .85rem;
    display: flex;
    align-items: flex-start;
    .card-title-element {
      padding-bottom: 3px;
    }
    .card-title-stats {
      padding: 1px 0;
      svg {
        margin-right: 3px;
        margin-top: -2px;
      }
    }
  }
  .clickmap-card-index {
    font-size: .65rem;
    margin-top: 2px;
    background-color: $color-danger;
    border-radius: 4px;
    padding: 2px 5px;
    color: #fff;
  }
  .clickmap-card-title-text {
    padding: 2px 0;
    margin-left: 5px;
    position: relative;
    bottom: -1px;
  }

  &:hover {
    box-shadow: inset 0 0 0 3px rgba($color-primary, .25);
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 510px;
  &.sm {
    max-width: 210px;
  }
}