
.pathing-steps-container {
    border: 2px dashed #eee;
    margin: 60px;
    padding: 60px 0;

    &.submitted {
        .step-col.invalid {
            .counter {
                background-color: #f00;
            }
        }
    }

    &.invalid {
        .step-col:last-child {
            .line {
                display: none;
            }
        }
    }

    &.max-steps {
        &>.row>.step-col:last-child .line {
            display: none !important;
        }
    }
}

  .step-col+.step-col {
      margin-top: 30px;
  }

  .step-col {
      &>.btn-add {
          position: absolute;
          left: 0;
          padding: 0 8px;
          box-shadow: none !important;

          .fa {
              margin-right: 10px;
          }
      }

      .line {
          display: none;

          &.show {
              display: block;
          }
      }

      &.valid {
          .line {
              display: block;
          }
      }
  }

  .add-btn-col {
      margin-top: 30px;

      &>.btn-add {
          margin-left: 20px;
      }
  }

  .pathing-step {
      display: flex;
      position: relative;
      align-items: flex-start;

      &>.pathing-step-form {
          width: 100%;
          padding-left: 30px;
          padding-right: 30px;

          &>.rule-wrapper {
              padding: 15px 0;

              &>label {
                  &>.fa {
                      margin-right: 10px;
                  }
              }
          }
      }

      &>.counter-col {
          flex-shrink: 0;
          position: relative;
          top: 4px;
          width: 45px;
          text-align: center;

          &>.counter {
              display: inline-block;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              font-size: 12px;
              text-align: center;
              line-height: 32px;
              color: #fff;
              background-color: #ccc;
          }
      }

      &>.line {
          position: absolute;
          left: 22px;
          top: 40px;
          bottom: -30px;
          border-right: 1px dashed #ccc;
      }
  }

.rule {
    position: relative;
    margin-bottom: 0;

    &>.rule-group {
        display: flex;
        flex-wrap: wrap;
        padding-right: 200px;

        &>.conj {
            padding-right: 10px;
            padding-left: 10px;
            line-height: 30px;
        }
    }

    &.funnel-mode {
        .rule-group {
            padding-right: 50px;
        }
    }

    .field-col {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        padding: 0 5px;
        margin-bottom: 10px;

        &>.conj {
            font-size: 10px;
            display: none;
            margin-right: 10px;
        }
    }

    .value-field-container {
        position: relative;
        flex-basis: 180px;
        flex-shrink: 0;
        padding-right: 35px;

        &.with-unit {
            flex-basis: 130px;
            padding-right: 0;
        }

        &>.btn-delete {
            padding: 0;
            position: absolute;
            right: 15px;
            box-shadow: none;
        }
    }

    .value-field-container+.value-field-container {
        &>.conj {
            display: inline-block;
            align-items: center;
        }
    }

    &>.actions {
        position: absolute;
        right: 0;
        top: -3px;

        &>span {
            margin-right: 10px;
            position: relative;
            bottom: -1px;
        }
    }

    &+.filter-separator {
        margin-top: 5px;

        &:after {
            border-bottom-style: dashed;
        }
    }

    &.condition {
        &>.rule-group {
            padding-left: 70px;

            &>.conj {
                position: absolute;
                left: 0;
            }
        }

        .add-condition-btn-container {
            display: none;
        }

        &:nth-child(1)>.rule-group {
            padding-left: 70px;
        }

        &:last-child {
            .add-condition-btn-container {
                display: block;
            }
        }
    }
}

.filter-separator {
    font-size: 9px;
    position: relative;
    height: 20px;
    margin: 0 0 10px 5px;
    line-height: 20px;

    &>span {
        position: absolute;
        left: 0;
        top: 0;
    }

    &:after {
        content: " ";
        display: block;
        border-bottom: 1px solid #b7b9cc;
        position: absolute;
        top: 10px;
        left: 30px;
        right: 0;
        opacity: .5;
    }
}
.pathing-container {
    margin-top: 15px;
    margin-bottom: 10px;
    border-radius: 2px;

    &>.container-header {
        font-size: .8rem;
        border-bottom: 1px solid #ddd;
        padding: 10px 15px;
        margin: 0;

        &>.btn {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &>.container-body {
        padding: 15px 15px 10px 15px;
    }
}

.pathing-form {
        padding: 15px 45px;
        margin-top: 7px;
        border-bottom: 2px solid #eee;
    }

.explanation {
    display: flex;
    position: relative;
    justify-content: center;
    align-content: center;
    margin: 10px 0 0 0;
    padding: 20px 20px;
    background:#E1E3E5;
    border-radius: 5px;
    
    &.message {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #000000;
    }
}